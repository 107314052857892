<template>
  <v-container class="text-center">
    <The404 />
  </v-container>
</template>

<script>
import The404 from "@/components/Fallbacks/The404";
export default {
  components: { The404 },
};
</script>

